<template>
    <div v-if="Authorize">
        <div class="page-top">
            <search-form :searchForm="tableConfig.searchForm" @handleQuery="handleQuery" :buttons="buttonConfig"
                @handleButtonClick="callMethod"></search-form>
        </div>
        <div class="page-dialog">
            <merge-pending ref="mergePending" v-if="dialogVisible" @handleQuery="handleQuery"></merge-pending>
        </div>
        <grid-table ref="delivered" :tableConfig="tableConfig" @selectionChange="selectionChange">
            <template slot="TradeInfo" slot-scope="{ params }">
                <div v-if="params.row.Status > 1">
                    <el-row v-for="(detail, index) in params.row.DetailsContent"
                        :key="params.row.TradePendingID + detail.Title + index">
                        <el-col :span="4">
                            {{ detail.Title }}
                        </el-col>
                        <el-col :span="20" style="text-align:left;">
                            <el-row v-for="(itemDetail, itemIndex) in detail.Content"
                                :key="params.row.TradePendingID + detail.Title + index + itemIndex">
                                <el-col :span="3">
                                    {{ itemDetail.ItemContent }}
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </div>
                <div v-if="params.row.Status <= 1">
                    <el-row>
                        <el-col :span="4">货号</el-col>
                        <el-col :span="20">颜色/尺码</el-col>
                    </el-row>
                    <el-row v-for="(detail, index) in params.row.FormatDetails"
                        :key="params.row.TradePendingID + detail.ItemNo + index">
                        <el-col :span="4">
                            <el-checkbox v-model="detail.IsNoneStock" @change="itemNoChkChange(detail)" label="断"
                                border />{{ detail.ItemNo }}
                        </el-col>
                        <el-col :span="20">
                            <el-row v-for="(colorDetail, colorIndex) in detail.ColorDetails"
                                :key="detail.ItemNo + colorDetail.Color + colorIndex">
                                <el-col :span="3">
                                    <el-checkbox v-model="colorDetail.IsNoneStock" @change="colorChkChange(colorDetail)"
                                        label="断" border />{{
                                            colorDetail.Color
                                        }}
                                </el-col>
                                <el-col :span="21" class="size-container">
                                    <el-tag :title="detail.ItemNo + colorDetail.Color + sizeDetail.Size" class="size-tag"
                                        v-for="(sizeDetail, sizeIndex) in colorDetail.SizeDetails"
                                        :key="detail.ItemNo + colorDetail.Color + sizeDetail.Size + sizeIndex">
                                        <el-checkbox v-model="sizeDetail.IsNoneStock"
                                            :disabled="sizeDetail.sizeChkDisabled == true"
                                            @change="sizeChkChange(sizeDetail)" label="断" border />
                                        {{ sizeDetail.Size }}*{{ sizeDetail.Num }}
                                        {{ sizeDetail.IsNoneStock ? "余货" : "缺货" }}
                                        <el-input class="out-stock" v-model="sizeDetail.OutStockNum"
                                            :blur="inputBlur(sizeDetail)" @input="changeInputNum(sizeDetail)"
                                            :disabled="sizeDetail.IsNoneStock"
                                            onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"></el-input>
                                    </el-tag>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24" class="">
                            <!--<el-button type="primary" @click="hasStock(params.row)">全部有货</el-button><el-button type="primary" @click="noneStock(params.row)">缺/断货</el-button>-->
                            <el-button type="primary" @click="noneStock(params.row)">保存</el-button>
                        </el-col>
                    </el-row>
                </div>

            </template>
        </grid-table>
    </div>
    <div style="position: absolute;top:0;left:0;right:0;bottom:0;margin:auto;width:200px;height:50px;" v-else>
        <el-button style="width:200px;height:50px;font-size:20px;" type="primary" @click="toAuthorize()">点击开通</el-button>
    </div>
</template>
<script>
import { submit } from "@/api/base";
import MergePending from "./components/MergePending.vue";
export default {
    name: "stallTradePending",
    components: {
        MergePending,
    },
    data() {
        return {
            dialogVisible: false,
            buttonConfig: [
                {
                    label: "合并处理",
                    icon: "ri-file-text-fill",
                    color: "#7d7ec3",
                    method: "mergeTrades",
                },
                {
                    label: "刷新",
                    icon: "ri-refresh-fill",
                    color: "#10cf10",
                    method: "handleSearch",
                },
            ],
            tableConfig: {
                url: "/api/stallPending/getList",
                searchForm: {
                    data: {
                        purchaserName: "",
                        startTime: "",
                        endTime: "",
                    },
                    items: [
                        {
                            label: "客户",
                            prop: "purchaserName",
                            type: "input",
                            width: "185px",
                        },
                        {
                            label: "创建日期",
                            prop: "startTime",
                            type: "datetime",
                            width: "185px",
                        },
                        {
                            label: "-",
                            prop: "endTime",
                            type: "datetime",
                            width: "185px",
                        }
                    ],
                },
                columns: [
                    {
                        label: "客户名称",
                        prop: "PurchaserName",
                        type: "text",
                        width: "150px",
                    },
                    {
                        label: "报单时间",
                        prop: "CreateTime",
                        type: "text",
                        width: "170px",
                    },
                    {
                        label: "状态",
                        prop: "StatusStr",
                        type: "text",
                        width: "100px",
                    },
                    {
                        label: "报单内容",
                        prop: "TradeInfo",
                        props: ["Details", "DetailsContent"],
                        type: "custom-operation",
                        width: "800px",
                    },
                ],
            },
            selectRows: [],
            Authorize: false,
        };
    },
    async mounted() {
        const { data } = await submit("/api/stallPending/checkAuthorize", {});
        if (data && data == "Y") {
            this.Authorize = true;
        }
        this.$nextTick(() => {
            if (this.Authorize) {
                this.handleQuery();
            }
        });
    },
    methods: {
        callMethod(button) {
            let methods = this.$options.methods;
            const _this = this;
            methods[button.method](_this, button);
        },
        handleSearch(_this, button) {
            _this.handleQuery();
        },
        async handleQuery() {
            this.$refs.delivered.fetchData();
        },
        selectionChange(selection) {
            this.selectRows = selection;
        },
        itemNoChkChange(detail) {
            const that = this;
            detail.ColorDetails.forEach((colorDetail) => {
                colorDetail.IsNoneStock = detail.IsNoneStock;
                that.colorChkChange(colorDetail);
            });
            //if (detail.IsNoneStock) {
            //    that.$baseConfirm(`后续所有断货SKU均默认为断货吗？`, '默认断货', async () => {
            //        detail.ColorDetails.forEach((colorDetail) => {
            //            that.colorChkChange(colorDetail, true);
            //        });
            //    });
            //} else {
            //    detail.ColorDetails.forEach((colorDetail) => {
            //        that.colorChkChange(colorDetail, false);
            //    });
            //}
        },
        colorChkChange(colorDetail, afterNone) {
            const that = this;
            colorDetail.SizeDetails.forEach((sizeDetail) => {
                sizeDetail.IsNoneStock = colorDetail.IsNoneStock;
                sizeDetail.AfterNoneStock = afterNone == true;
            });
            //if (afterNone == undefined) {
            //    if (colorDetail.IsNoneStock) {
            //        that.$baseConfirm(`后续所有断货SKU均默认为断货吗？`, '默认断货', async () => {
            //            colorDetail.SizeDetails.forEach((sizeDetail) => {
            //                sizeDetail.AfterNoneStock = true;
            //            });
            //        });
            //    } else {
            //        colorDetail.SizeDetails.forEach((sizeDetail) => {
            //            sizeDetail.AfterNoneStock = false;
            //        });
            //    }
            //}
        },
        sizeChkChange(sizeDetail) {
            const that = this;
            sizeDetail.AfterNoneStock = false;
            if (sizeDetail.IsNoneStock) {
                sizeDetail.OutStockNum = 0;
                //    that.$baseConfirm(`后续所有断货SKU均默认为断货吗？`, '默认断货', async () => {
                //        sizeDetail.AfterNoneStock = true;
                //    });
            }
        },
        inputBlur(sizeDetail) {
            if (sizeDetail.OutStockNum == "") {
                sizeDetail.OutStockNum = 0;
            }
        },
        changeInputNum(sizeDetail) {
            if (sizeDetail.OutStockNum > 0) {
                sizeDetail.IsNoneStock = false;
                sizeDetail.sizeChkDisabled = true;
            } else {
                sizeDetail.sizeChkDisabled = false;
            }
        },
        async toAuthorize() {
            const { data } = await submit("/api/stallPending/authorize", {});
            if (data && data == "success") {
                this.Authorize = true;
                this.$nextTick(() => {
                    this.handleQuery();
                });
            }
        },
        async mergeTrades(_this, button) {
            if (!_this.selectRows || _this.selectRows.length == 0) {
                _this.$baseMessage("请选择要合并的报单", "error");
                return;
            }
            if (_this.selectRows.length <= 1) {
                _this.$baseMessage("至少选择2条", "error");
                return;
            }
            let ids = _this.selectRows.map((row) => row["TradePendingID"]).join();
            const { data, msg } = await submit("/api/stallPending/getMergeInfo", { "TradePendingIDs": ids });
            if (data) {
                _this.dialogVisible = true;
                _this.$nextTick(() => {
                    _this.$refs.mergePending.init(data);
                });
            } else {
                _this.$baseMessage(`获取合并报单的数据失败，原因：${msg}`, "error"); return;
            }
        },
        async hasStock(data) {
            const _this = this;
            _this.$baseConfirm(`确定当前报单全部有货并提交至待配货？`, null, async () => {
                const id = data.TradePendingID;
                const { msg } = await submit("/api/stallPending/updateTradeStock", {
                    TradePendingIDs: id,
                    HasStock: true
                });
                if (msg) {
                    _this.$baseMessageBox(
                        `全部有货设置成功，${msg}`,
                        `提示`
                    );
                } else {
                    _this.$baseMessage("全部有货设置成功", "success");
                }
                _this.handleQuery();
            });
        },
        async saveStockInfo(_this, request) {
            const { msg } = await submit("/api/stallPending/updateTradeStock", request);
            if (msg) {
                _this.$baseMessageBox(
                    `缺/断货设置成功，${msg}`,
                    `提示`
                );
            } else {
                _this.$baseMessage(`缺/断货设置成功`, "success");
            }
            _this.handleQuery();
        },
        async noneStock(data) {
            const _this = this;
            const request = {
                TradePendingIDs: data.TradePendingID,
                HasStock: false,
                StockDetails: []
            };
            var outStockNum = 0;
            var noneStockNum = 0;
            data.FormatDetails.forEach((detail) => {
                detail.ColorDetails.forEach((colorDetail) => {
                    colorDetail.SizeDetails.forEach((sizeDetail) => {
                        if (sizeDetail.IsNoneStock) {
                            if (sizeDetail.IsSetNoneStock != true) {
                                noneStockNum = noneStockNum + 1;
                            }
                            request.StockDetails.push({
                                ItemNo: detail.ItemNo,
                                Color: colorDetail.Color,
                                Size: sizeDetail.Size,
                                NoneStock: true,
                                OutStockNum: sizeDetail.OutStockNum,
                                AfterNoneStock: (sizeDetail.AfterNoneStock != undefined && sizeDetail.AfterNoneStock == true)
                            });
                        } else {
                            if (sizeDetail.IsSetOutStock != true) {
                                outStockNum = outStockNum + sizeDetail.OutStockNum;
                            }
                            if (sizeDetail.OutStockNum > 0) {
                                request.StockDetails.push({
                                    ItemNo: detail.ItemNo,
                                    Color: colorDetail.Color,
                                    Size: sizeDetail.Size,
                                    OutStockNum: sizeDetail.OutStockNum
                                });
                            }
                        }
                    });
                });
            });
            if (request.StockDetails.length <= 0) {//没设置缺、断货则直接提交保存
                _this.hasStock(data);
                return;
            }
            if (noneStockNum > 0) {
                _this.$baseConfirm(`后续所有断货SKU均默认为断货吗？`, '默认断货', async () => {
                    request.AfterNoneStock = true;
                    _this.doOutStockConfirm(_this, request, outStockNum);
                }, async () => {
                    request.AfterNoneStock = false;
                    _this.doOutStockConfirm(_this, request, outStockNum);
                });
            } else {
                request.AfterNoneStock = false;
                _this.doOutStockConfirm(_this, request, outStockNum);
            }
        },
        async doOutStockConfirm(_this, request, outStockNum) {
            if (outStockNum > 0) {
                _this.$baseConfirm(`当天的缺货SKU，均默认为缺货吗？`, "默认缺货", async () => {
                    request.AfterOutStock = true;
                    _this.$baseConfirm(`确定保存当前报单缺/断货信息并提交至待配货？`, null, async () => {
                        _this.saveStockInfo(_this, request);
                    });
                }, async () => {
                    _this.$baseConfirm(`确定保存当前报单缺/断货信息并提交至待配货？`, null, async () => {
                        _this.saveStockInfo(_this, request);
                    });
                });
            } else {
                _this.$baseConfirm(`确定保存当前报单缺/断货信息并提交至待配货？`, null, async () => {
                    _this.saveStockInfo(_this, request);
                });
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.page-top {
    padding: 6px 14px 0 14px;
}

.size-container {
    text-align: left;
    white-space: break-spaces;

    .size-tag:first-child {
        margin-left: 10px;
    }

    .size-tag {
        font-size: 14px;
        height: 30px;
        margin-bottom: 5px;

        .out-stock {
            width: 50px;

            ::v-deep {
                .el-input__inner {
                    height: 25px !important;
                    vertical-align: middle;
                }
            }
        }
    }
}

::v-deep {
    .el-checkbox {
        padding: 2px 5px !important;
        height: 20px !important;
        line-height: 13px !important;
        margin-right: 2px !important;

        &__label {
            padding-left: 2px !important;
        }
    }
}

.statistics {
    border-top: 1px solid #e9edf1;
    margin-top: 5px;
    width: 100%;

    div {
        display: inline-block;
        height: 120px;
        width: 400px;
        line-height: 120px;
        font-size: 18px;
        text-align: center;
    }
}

.payment-tag {
    width: 100px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 13.3px;
}
</style>
